import { CheckInEntry } from "../models/checkInEntry";
import { usePost } from "../hooks/usePost";
import API_PATHS from "../paths";

export const useCheckInCreate = (handlers: {
	onSuccess?: () => void;
	onError?: () => void;
}) => {
	return usePost<CheckInEntry, unknown>({
		relativePath: API_PATHS.Employee,
		...handlers,
		toastMessage: {
			error: "Unable to check-in to this location.",
			success: "You have successfully checked in.",
		},
	});
};
