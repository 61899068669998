import { FC } from "react";
import { useState } from "react";
import styles from "./locationOptions.module.css";
//import Button from "../common/button/button";
import ReactiveButton from "reactive-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMoon,
	faSun,
} from "@fortawesome/free-solid-svg-icons";

type LocationOptionProps = {
	selectedLocation: string;
	onClick: (location: string) => void;
};

export const LocationOptions: FC<LocationOptionProps> = (props) => {
	const [pstate, setState] = useState("idle");
	const [kstate, setState1] = useState("idle");

	const onClickHandler = (location: string) => {
		if (location === "Pearland") setState("loading");
		else setState1("loading");

		// send an HTTP request
		setTimeout(() => {
			setState("success");
			setState1("success");
		}, 2000);

		props.onClick(location);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<div className={styles.card}>
					<h1 className={styles.title}>Employee Sign In!</h1>
					<p>
						Select your current location
						<br />
						to sign-in.
					</p>
					{/* <br /> */}

					<div className={styles.buttonContainer}>
						<ReactiveButton
							buttonState={pstate}
							onClick={() => onClickHandler("Pearland")}
							color="green"
							size="large"
							idleText={<span> P e a r l a n d </span>}
							loadingText={
								<>
									<span>
										<FontAwesomeIcon
											icon={faSun}
											spin
										/>{" "}
									</span>{" "}
									Loading...
								</>
							}
							successText={
								<>
									<FontAwesomeIcon icon={faMoon} /> Success
								</>
							}
							height="60px"
							rounded
							animation={true}
							style={{ fontWeight: "bold", fontPalette: "dark" }}
						/>
						<ReactiveButton
							buttonState={kstate}
							onClick={() => onClickHandler("Kirby")}
							color="blue"
							size="large"
							idleText="K i r b y"
							loadingText={
								<>
									<span>
										<FontAwesomeIcon
											icon={faSun}
											spin
										/>{" "}
									</span>{" "}
									Loading...
								</>
							}
							successText={
								<>
									<FontAwesomeIcon icon={faMoon} /> Success
								</>
							}
							height="60px"
							rounded
							animation={true}
							style={{ fontWeight: "bold", fontPalette: "dark" }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LocationOptions;
