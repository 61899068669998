// import { DateTime } from 'luxon'

export const capitalizeFirstCharacter = (word?: string, emptyString?: string) => {
    if (word) return word.charAt(0).toUpperCase() + word.slice(1)
    else return emptyString || '-'
}

export const capitalizeOrReplace = (emptyString: string, word?: string | null) => {
    if (word && word.length > 0) return capitalizeFirstCharacter(word)
    return emptyString
}

export const formatFileSize = (size: number) => {
    var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
}

export const htmlHasContent = (html?: string | null): boolean => {
    if (!html) return false
    else {
        const strippedHtml = html!.replace(/<[^>]+>/g, '')
        return strippedHtml.trim().length > 0
    }
}

// export const formatDateString = (date: string) => {
//     return DateTime.fromJSDate(new Date(date)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
// }

export const truncateHTML = (numberOfCharacters: number, htmlString?: string | null) => {
    if (!htmlString) return ''

    let modifiedHTMLString = htmlString.replace(/(<(?:ul|ol)>)(\n+)(<li>)/g, '$1$3')

    // Remove HTML tags using regex
    const textContent = modifiedHTMLString.replace(/<[^>]+>/g, '')

    const contentWithDots = textContent.replace(
        /(\n(?!\n|$))(?!<\/?(ol|ul)[^>]*>|<li>)(?!<\/li>)/g,
        '. '
    )
    // Extract the first 30 characters from the text content
    const truncatedText = contentWithDots.substring(0, numberOfCharacters)

    // Check if there are more characters in the text content
    if (textContent.length > numberOfCharacters) {
        return `${truncatedText}...`
    } else {
        return truncatedText
    }
}
