import { FC, ReactNode, createContext, useContext, useState } from "react";
import { Status } from "../../models/status";
import useAuth from "./useAuth";
import { useGetStatus } from "../../services/status";

export interface StatusContextValue {
	status: Status[] | Status;
	isLoading: boolean;
	updateStatus: (newStatus: Status[] | Status) => void;
	refetch: () => void;
}

export const StatusContext = createContext<StatusContextValue>({
	status: {},
	isLoading: true,
	updateStatus: () => {},
	refetch: () => {},
});

export const StatusProvider: FC<{ children?: ReactNode }> = (props) => {
	const { authenticatedAccount } = useAuth();
	const [status, setStatus] = useState<Status[] | Status>({});
	const [isLoading, setIsLoading] = useState(true);

	const { isLoading: isFetching, refetch } = useGetStatus({
		onSuccess: (data) => {
			setStatus(data);
		},
		finishedLoading: (finishedLoadingValue) => {
			setIsLoading(!finishedLoadingValue);
		},
		disabled: !authenticatedAccount,
	});

	const updateStatus = (newStatus: Status[] | Status) => {
		setStatus(newStatus);
	};

	return (
		<StatusContext.Provider
			value={{
				status,
				updateStatus,
				isLoading: isFetching || isLoading,
				refetch,
			}}
		>
			{props.children}
		</StatusContext.Provider>
	);
};

export const useStatus = (): StatusContextValue => useContext(StatusContext);

export default useStatus;
