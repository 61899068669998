import { FC } from "react";
// import { useNavigate } from "react-router-dom";
import styles from "./successful.module.css";
// import Button from "../common/button/button";

type SuccessProps = {
	location: string | null;
};

export const Success: FC<SuccessProps> = (props) => {
	// const navigate = useNavigate();

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<div className={styles.card}>
					<div className={styles.successAnimation}>
						<svg
							className={styles.checkmark}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 52 52"
						>
							<circle
								className={styles.checkmarkCircle}
								cx="26"
								cy="26"
								r="25"
								fill="none"
							/>
							<path
								className={styles.checkmarkCheck}
								fill="none"
								d="M14.1 27.2l7.1 7.2 16.7-16.8"
							/>
						</svg>
					</div>
					<div className={styles.successfulContent}>
						<h1>Success!</h1>
						<p>You have successfully signed into the <b>{props.location}</b> location</p>
						{/* <Button
							onClick={() => navigate("/check-in")}
							variant="blue-bg"
							size="large"
							text="Change Location"
						/> */}
					</div>
				</div>
			</div>
		</div>
	);
};
