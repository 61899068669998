import { Routes, Route } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import LoginPage from "./pages/LoginPage";
import CheckInPage from "./pages/CheckInPage";
import SuccessfulPage from "./pages/SuccessfulPage";

export const PageRouter = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={<LoginPage />}
			/>
			<Route
				path="/check-in"
				element={
					<AuthGuard>
						<CheckInPage />
					</AuthGuard>
				}
			/>
			<Route
				path="/successful"
				element={
					<AuthGuard>
						<SuccessfulPage />
					</AuthGuard>
				}
			/>
		</Routes>
	);
};
