import { useEffect, useState } from "react";
import { Toast } from "../components/common/toast/toast";
import { SIGNIN_API_URL } from "../utils/constants";
import { useMsal } from "@azure/msal-react";
import useAuth from "./context/useAuth";

type UseGetProps<T> = {
	relativePath: string;
	disabled?: boolean;
	toastErrorText: string;
	onSuccess?: (data: T) => void | Promise<void>;
	onError?: () => void;
	finishedLoading?: (finishedLoading: boolean) => void;
};

export function useGet<RequestResponse>(props: UseGetProps<RequestResponse>) {
	const { instance } = useMsal();
	const { signOut } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [refetchFlag, setRefetchFlag] = useState(false);
	const [data, setData] = useState<RequestResponse>();

	const refetch = () => {
		setRefetchFlag((prev) => !prev);
	};

	useEffect(() => {
		const getData = async () => {
			if (!props.disabled) {
				setIsLoading(true);
				const { accessToken } = await instance.acquireTokenSilent({
					scopes: ["user.read"],
				});
				if (accessToken) {
					const requestOptions = {
						headers: {
							Authorization: `Bearer ${accessToken}`,
							"Content-Type": "application/json",
						},
					};
					fetch(`${SIGNIN_API_URL}${props.relativePath}`, requestOptions)
						.then(async (response) => {
							if (response.status === 204) {
								return props.finishedLoading?.(true);
							} else if (response.status === 511) {
								Toast({ type: "error", message: props.toastErrorText });
								signOut();
							} else {
								const data: RequestResponse = await response.json();
								setData(data);
								props.onSuccess?.(data);
								props.finishedLoading?.(true);
							}
						})
						.catch(() => {
							props.onError?.();
						})
						.finally(() => {
							setIsLoading(false);
						});
				} else {
					setIsLoading(false);
				}
			}
		};
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.relativePath, refetchFlag, props.disabled]);

	return { isLoading, data: data || [], refetch };
}
