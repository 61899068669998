import { useState } from "react";
import { SIGNOUT_API_URL } from "../utils/constants";
import { useMsal } from "@azure/msal-react";
import { Status } from "../models/status";

type UseGetProps = {
	relativePath: string;
	disabled?: boolean;
	onSuccess?: () => void;
	onError?: () => void;
	updateStatus: (newStatus: Status[] | Status) => void;
};

export function useSignout(props: UseGetProps) {
	const { instance } = useMsal();
	const [isLoading, setIsLoading] = useState(false);

	const mutation = async () => {
		try {
			setIsLoading(true);
			const { accessToken } = await instance.acquireTokenSilent({
				scopes: ["user.read"],
			});
			if (accessToken) {
				const requestOptions = {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						"Content-Type": "application/json",
					},
				};
				const response = await fetch(
					`${SIGNOUT_API_URL}${props.relativePath}`,
					requestOptions
				);
				if (response.ok) {
					props.onSuccess?.();
					props.updateStatus({});
				} else {
					props.onError?.();
				}
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			console.error("Error occurred during signout.", error);
			props.onError?.();
		} finally {
			setIsLoading(false);
		}
	};

	return { isLoading, mutation };
}
