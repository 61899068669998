import { FC, useState, useEffect } from "react";
import styles from "./signout.module.css";
import ReactiveButton from "reactive-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/context/useAuth";
import useStatus from "../../hooks/context/useStatus";

type SignoutProps = {
	onClick: () => void;
};

export const Signout: FC<SignoutProps> = (props) => {
	const [pstate, setState] = useState("idle");
	const { authenticatedAccount } = useAuth();
	const { status } = useStatus();
	const [location, setLocation] = useState<string | null>(null);

	useEffect(() => {
		if (status && "location" in status) {
			setLocation(status.location as string);
		}
	}, [status]);

	const onClickHandler = () => {
		setState("loading");
		props.onClick();
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<div className={styles.card}>
					<h1 className={styles.title}>Employee Signed In!</h1>
					<p>
						<b>{authenticatedAccount?.name}</b> please sign out
						<br />
						of <b>{location}</b> to select a different location.
					</p>
					<br />

					<div className={styles.buttonContainer}>
						<ReactiveButton
							buttonState={pstate}
							onClick={onClickHandler}
							color="red"
							size="large"
							idleText={<span> S I G N O U T </span>}
							loadingText={
								<>
									<span>
										<FontAwesomeIcon
											icon={faSun}
											spin
										/>{" "}
									</span>{" "}
									Loading...
								</>
							}
							successText={
								<>{/* <FontAwesomeIcon icon={faMoon} /> Success */}</>
							}
							height="60px"
							rounded
							animation={true}
							style={{ fontWeight: "bold", fontPalette: "dark" }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Signout;
