import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/layout/layout";
import LocationOptions from "../components/location-options/locationOptions";
import Signout from "../components/signout/signout";
import useAuth from "../hooks/context/useAuth";
import { getFormattedDate } from "../utils/constants";
import { useCheckInCreate } from "../services/checkInEntry";
import useStatus from "../hooks/context/useStatus";
import { useSignout } from "../hooks/useSignout";
import API_PATHS from "../paths";

const CheckInPage = () => {
	const navigate = useNavigate();
	const { authenticatedAccount } = useAuth();
	const [selectedLocation, setSelectedLocation] = useState<string>("");
	const {
		status,
		updateStatus,
		isLoading,
		refetch: refetchStatus,
	} = useStatus();
	const formattedDate = getFormattedDate();

	const isEmpty = Object.keys(status).length === 0;

	const { mutation: createCheckIn } = useCheckInCreate({
		onSuccess: () => {
			navigate("/successful");
			refetchStatus();
		},
		onError: () => {
			console.error("Error occurred during check-in.");
		},
	});

	const handleCheckIn = (location: string) => {
		setSelectedLocation(location);
		sessionStorage.setItem("Location", location);
		const checkInData = {
			location: location,
			submittedby: authenticatedAccount!.name,
			submittedtime: formattedDate,
			submittedid: authenticatedAccount!.localAccountId,
			lastmodifiedby: authenticatedAccount!.name,
			lastmodifiedtime: formattedDate,
			lastmodifiedid: authenticatedAccount!.localAccountId,
		};
		createCheckIn(checkInData);
		// console.log(`Checked Into ${location}`, checkInData);
	};

	const { mutation: signoutMutation } = useSignout({
		updateStatus,
		relativePath: API_PATHS.Employee,
		onSuccess: () => {
			updateStatus({});
		},
		onError: () => {
			console.error("Error occurred during signout.");
		},
	});

	const handleSignout = () => {
		signoutMutation();
	};

	return (
		<Layout isLoading={isLoading}>
			{isEmpty && (
				<LocationOptions
					selectedLocation={selectedLocation}
					onClick={handleCheckIn}
				/>
			)}
			{!isEmpty && <Signout onClick={handleSignout} />}
		</Layout>
	);
};

export default CheckInPage;
