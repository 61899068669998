import { useGet } from "../hooks/useGet";
import { Status } from "../models/status";
import API_PATHS from "../paths";

export const useGetStatus = (handlers?: {
	finishedLoading?: (finishedLoadingValue: boolean) => void;
	onSuccess?: (data: Status[]) => void;
	onError?: () => void;
	disabled?: boolean;
}) => {
	return useGet<Status[]>({
		relativePath: API_PATHS.EmployeeStatus,
		finishedLoading: handlers?.finishedLoading,
		toastErrorText: "Must be connected to Syzygy network.",
		...handlers,
	});
};
