export const MOBILE_BREAKPOINT = 768;

export function getFormattedDate() {
	const currentDate = new Date();
	const hour = currentDate.getHours();
	const amPM = hour >= 12 ? "PM" : "AM";
	const formattedHour = hour % 12 || 12;

	return (
		String(currentDate.getMonth() + 1).padStart(2, "0") +
		"-" +
		String(currentDate.getDate()).padStart(2, "0") +
		"-" +
		currentDate.getFullYear() +
		" " +
		(formattedHour < 10 ? " " : "") +
		formattedHour +
		":" +
		String(currentDate.getMinutes()).padStart(2, "0") +
		":" +
		String(currentDate.getSeconds()).padStart(2, "0") +
		" " +
		amPM
	);
}

// export const REACT_APP_API_URL =
// "https://signinsheetbook.azurewebsites.net/api/signin";
// "https://operatorlogapi.azurewebsites.net/api/lognotes";
export const SIGNIN_API_URL =
	"https://signinsheetapi.azurewebsites.net/api/signin";
export const SIGNOUT_API_URL =
	"https://signinsheetapi.azurewebsites.net/api/signout";
export const REACT_APP_APPLICATION_CLIENT_ID =
	// '9ea11ac6-6509-4264-8bff-12e600f3b084'
	"f565dd47-480d-4957-8667-faf496dd1011";

export const REACT_APP_APPLICATION_AUTHORITY =
	// 'https://login.microsoftonline.com/9dee0590-9ade-48bf-8659-402111e6efea'
	"https://login.microsoftonline.com/3b92c69d-ffab-4f60-9cea-e368ca2607ee";
