import { Success } from "../components/success/success";
import { Layout } from "../components/layout/layout";
import useAuth from "../hooks/context/useAuth";

const SuccessfulPage = () => {
	const { authenticatedAccount } = useAuth();
	const location = sessionStorage.getItem("Location");

	return (
		<Layout isLoading={!authenticatedAccount}>
			<Success location={location} />
		</Layout>
	);
};

export default SuccessfulPage;
